(function ($R) {
  $R.add('plugin', 'noopener-noreferrer', {
    onlink: {
      inserted: function (link) {
        if (link.opts.linkNewTab) {
          link.attr('rel', 'noopener noreferrer');
        }
      },
    },
  });
})(Redactor);
