(function ($R) {
  $R.add('plugin', 'video', {
    translations: {
      en: {
        video: 'Video',
        'video-html-code': 'Youtube or Vimeo video URL (not the embed code)',
      },
    },
    modals: {
      video:
        '<form action=""> \
          <div class="form-item"> \
            <label for="modal-video-input">## video-html-code ##</label> \
            <input id="modal-video-input" name="video"></textarea> \
          </div> \
        </form>',
    },
    init: function (app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.component = app.component;
      this.insertion = app.insertion;
      this.inspector = app.inspector;
      this.selection = app.selection;
    },
    // messages
    onmodal: {
      video: {
        opened: function ($modal, $form) {
          $video = $form.getField('video');
          $video.focus();
        },
        insert: function ($modal, $form) {
          var data = $form.getData();
          this._insert(data);
        },
      },
    },
    oncontextbar: function (e, contextbar) {
      var data = this.inspector.parse(e.target);
      if (data.isComponentType('video')) {
        var node = data.getComponent();
        var buttons = {
          remove: {
            title: this.lang.get('delete'),
            api: 'plugin.video.remove',
            args: node,
          },
        };

        contextbar.set(e, node, buttons, 'bottom');
      }
    },

    // public
    start: function () {
      var obj = {
        title: this.lang.get('video'),
        api: 'plugin.video.open',
      };

      var $button = this.toolbar.addButtonAfter('image', 'video', obj);
      $button.setIcon('<i class="re-icon-video"></i>');
    },
    open: function () {
      var options = {
        title: this.lang.get('video'),
        width: '600px',
        name: 'video',
        handle: 'insert',
        commands: {
          insert: { title: this.lang.get('insert') },
          cancel: { title: this.lang.get('cancel') },
        },
      };

      this.app.api('module.modal.build', options);
    },
    remove: function (node) {
      this.component.remove(node);
    },

    // private
    _insert: function (data) {
      this.app.api('module.modal.close');

      if (data.video.trim() === '') {
        return;
      }

      var youtubeId = this._youtubeId(data.video);
      var vimeoId = this._vimeoId(data.video);

      if (youtubeId) {
        data.video = `<lite-youtube videoid="${youtubeId}"></lite-youtube>`;
      } else if (vimeoId) {
        data.video = `<lite-vimeo videoid="${vimeoId}"></lite-vimeo>`;
      }

      var $video = this.component.create('video', data.video);
      this.insertion.insertHtml($video);
    },

    _youtubeId: function (url) {
      var regExp =
        /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      return match && match[1].length == 11 ? match[1] : null;
    },

    _vimeoId: function (url) {
      var regExp =
        /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
      var match = url.match(regExp);
      return match && match[1] ? match[1] : null;
    },
  });
})(Redactor);
