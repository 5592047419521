define('@fortawesome/pro-duotone-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faFileLines = {
    prefix: 'fad',
    iconName: 'file-lines',
    icon: [384, 512, [128441, 128462, 61686, "file-alt", "file-text"], "f15c", ["M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM112 256c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112z", "M384 160L224 0V128c0 17.7 14.3 32 32 32H384zM112 256c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H112z"]]
  };
  var faBullseyeArrow = {
    prefix: 'fad',
    iconName: 'bullseye-arrow',
    icon: [512, 512, [127919], "f648", ["M335.2 244.7c.5 3.7 .8 7.5 .8 11.3c0 44.2-35.8 80-80 80s-80-35.8-80-80s35.8-80 80-80c3.8 0 7.6 .3 11.3 .8l31.2-31.2L293.7 117c-12-3.3-24.7-5-37.7-5c-79.5 0-144 64.5-144 144s64.5 144 144 144s144-64.5 144-144c0-13.1-1.7-25.7-5-37.7l-28.6-4.8-31.2 31.2zm110.1-20.8c1.8 10.4 2.7 21.1 2.7 32.1c0 106-86 192-192 192S64 362 64 256S150 64 256 64c10.9 0 21.6 .9 32.1 2.7l9.3-9.3 43.1-43.1C314 5 285.6 0 256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256c0-29.6-5-58-14.3-84.5l-43.1 43.1-9.3 9.3z", "M331.2 146.9l-8.4-50.7c-1.7-10.2 1.6-20.6 8.9-27.9l51.2-51.2C391 9 404.7 11.9 409 22.5L432 80l57.5 23c10.7 4.3 13.5 18 5.4 26.2l-51.2 51.2c-7.3 7.3-17.7 10.6-27.9 8.9l-50.7-8.4L273 273c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l92.1-92.1z"]]
  };
  var faStarHalfStroke = {
    prefix: 'fad',
    iconName: 'star-half-stroke',
    icon: [576, 512, ["star-half-alt"], "f5c0", ["M288.1 376.3l-.1 .1v63.5l.1-.1 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18C311.6 7 300.4 0 288.1 0c-.5 0-1.1 0-1.6 0H288V87.1l.1-.3 42.7 87.9 12.9 26.6 29.3 4.3 96.1 14.2-70.1 69.3-20.7 20.5 4.8 28.7 16.5 97.6-85.2-45.5-26.4-14.1zM146.3 512c-1.1 .1-2.1 .1-3.2 0h3.2z", "M289.7 0H288V87.1 376.4v63.5L159.8 508.3C149 514 135.9 513.1 126 506s-14.9-19.3-12.9-31.3L137.8 329 33.6 225.9c-8.6-8.5-11.7-21.2-7.9-32.7s13.7-19.9 25.7-21.7L195 150.3 259.4 18c5.4-11 16.5-18 28.8-18c.5 0 1.1 0 1.6 0zM433.2 512c-1.1 .1-2.1 .1-3.2 0h3.2z"]]
  };
  var faFileImage = {
    prefix: 'fad',
    iconName: 'file-image',
    icon: [384, 512, [128443], "f1c5", ["M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM128 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm88 32c-5.3 0-10.2 2.6-13.2 6.9l-48.3 70.2-12.8-21.4c-2.9-4.8-8.1-7.8-13.7-7.8s-10.8 2.9-13.7 7.8l-48 80c-3 4.9-3 11.1-.2 16.1s8.2 8.1 13.9 8.1h48 48 40 88c5.9 0 11.4-3.3 14.2-8.6s2.4-11.6-1-16.5l-88-128c-3-4.3-7.9-6.9-13.2-6.9z", "M384 160L224 0V128c0 17.7 14.3 32 32 32H384zM229.2 294.9c-3-4.3-7.9-6.9-13.2-6.9s-10.2 2.6-13.2 6.9l-48.3 70.2-12.8-21.4c-2.9-4.8-8.1-7.8-13.7-7.8s-10.8 2.9-13.7 7.8l-48 80c-3 4.9-3 11.1-.2 16.1s8.2 8.1 13.9 8.1h48 48 40 88c5.9 0 11.4-3.3 14.2-8.6s2.4-11.6-1-16.5l-88-128z"]]
  };
  var faFileVideo = {
    prefix: 'fad',
    iconName: 'file-video',
    icon: [384, 512, [], "f1c8", ["M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM64 288v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32V288c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32zM300.9 397.9c2 1.3 4.4 2.1 6.8 2.1c6.8 0 12.3-5.5 12.3-12.3V284.3c0-6.8-5.5-12.3-12.3-12.3c-2.4 0-4.8 .7-6.8 2.1L256 304v64l44.9 29.9z", "M384 160L224 0V128c0 17.7 14.3 32 32 32H384zM64 288v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32V288c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32zM300.9 397.9c2 1.3 4.4 2.1 6.8 2.1c6.8 0 12.3-5.5 12.3-12.3V284.3c0-6.8-5.5-12.3-12.3-12.3c-2.4 0-4.8 .7-6.8 2.1L256 304v64l44.9 29.9z"]]
  };
  var faFilePdf = {
    prefix: 'fad',
    iconName: 'file-pdf',
    icon: [512, 512, [], "f1c1", ["M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64z", "M384 160L224 0l0 128c0 17.7 14.3 32 32 32l128 0zM176 352c-8.8 0-16 7.2-16 16l0 80 0 48c0 8.8 7.2 16 16 16s16-7.2 16-16l0-32 16 0c30.9 0 56-25.1 56-56s-25.1-56-56-56l-32 0zm32 80l-16 0 0-48 16 0c13.3 0 24 10.7 24 24s-10.7 24-24 24zm96-80c-8.8 0-16 7.2-16 16l0 128c0 8.8 7.2 16 16 16l32 0c26.5 0 48-21.5 48-48l0-64c0-26.5-21.5-48-48-48l-32 0zm32 128l-16 0 0-96 16 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16zm80-112l0 64 0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-48 32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0 0-32 32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-48 0c-8.8 0-16 7.2-16 16z"]]
  };
  var faFile = {
    prefix: 'fad',
    iconName: 'file',
    icon: [384, 512, [128196, 128459, 61462], "f15b", ["M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64z", "M224 0L384 160H256c-17.7 0-32-14.3-32-32V0z"]]
  };
  var faLink = {
    prefix: 'fad',
    iconName: 'link',
    icon: [640, 512, [128279, "chain"], "f0c1", ["M579.8 63.2c56.5 56.5 56.5 148 0 204.5L467.5 380c-56.5 56.5-148 56.5-204.5 0c-50-50-56.5-128.8-15.4-186.3l1.1-1.6c10.3-14.4 30.3-17.7 44.6-7.4s17.7 30.3 7.4 44.6l-1.1 1.6c-22.9 32.1-19.3 76 8.6 103.8c31.5 31.5 82.5 31.5 114 0L534.5 222.5c31.5-31.5 31.5-82.5 0-114C506.7 80.6 462.7 77 430.7 99.9l-1.6 1.1c-14.4 10.3-34.4 6.9-44.6-7.4s-6.9-34.4 7.4-44.6l1.6-1.1C451 6.7 529.8 13.3 579.8 63.2z", "M60.2 448.8c-56.5-56.5-56.5-148 0-204.5L172.5 132c56.5-56.5 148-56.5 204.5 0c50 50 56.5 128.8 15.4 186.3l-1.1 1.6c-10.3 14.4-30.3 17.7-44.6 7.4s-17.7-30.3-7.4-44.6l1.1-1.6c22.9-32.1 19.3-76-8.6-103.9c-31.5-31.5-82.5-31.5-114 0L105.5 289.5C74 321 74 372 105.5 403.5c27.9 27.9 71.8 31.5 103.8 8.6l1.6-1.1c14.4-10.3 34.4-6.9 44.6 7.4s6.9 34.4-7.4 44.6l-1.6 1.1C189 505.3 110.2 498.7 60.2 448.8z"]]
  };
  var faQrcode = {
    prefix: 'fad',
    iconName: 'qrcode',
    icon: [448, 512, [], "f029", ["M48 32C21.5 32 0 53.5 0 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H48zm80 64v64H64V96h64zM48 288c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48H48zm80 64v64H64V352h64zM256 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H304c-26.5 0-48 21.5-48 48zm64 16h64v64H320V96z", "M256 464V304c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s7.2-16 16-16s16 7.2 16 16v96c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s-7.2-16-16-16s-16 7.2-16 16v64c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zm128 0a16 16 0 1 1 -32 0 16 16 0 1 1 32 0zm64 0a16 16 0 1 1 -32 0 16 16 0 1 1 32 0z"]]
  };
  var faFileSpreadsheet = {
    prefix: 'fad',
    iconName: 'file-spreadsheet',
    icon: [384, 512, [], "f65b", ["M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM88 224c-17.7 0-32 14.3-32 32V416c0 17.7 14.3 32 32 32H296c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32H88z", "M384 160L224 0V128c0 17.7 14.3 32 32 32H384zM88 224c-17.7 0-32 14.3-32 32v16 80 64c0 17.7 14.3 32 32 32h64 80 64c17.7 0 32-14.3 32-32V352 272 256c0-17.7-14.3-32-32-32H88zm0 112V288h48v48H88zm80 0V288h48v48H168zm80 0V288h48v48H248zm0 32h48v48H248V368zm-32 0v48H168V368h48zm-80 0v48H88V368h48z"]]
  };

  exports.faBullseyeArrow = faBullseyeArrow;
  exports.faFile = faFile;
  exports.faFileImage = faFileImage;
  exports.faFileLines = faFileLines;
  exports.faFilePdf = faFilePdf;
  exports.faFileSpreadsheet = faFileSpreadsheet;
  exports.faFileVideo = faFileVideo;
  exports.faLink = faLink;
  exports.faQrcode = faQrcode;
  exports.faStarHalfStroke = faStarHalfStroke;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
