define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faSquareCheck = {
    prefix: 'fal',
    iconName: 'square-check',
    icon: [448, 512, [9745, 9989, 61510, "check-square"], "f14a", "M64 64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM331.3 203.3l-128 128c-6.2 6.2-16.4 6.2-22.6 0l-64-64c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L192 297.4 308.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"]
  };
  var faLock = {
    prefix: 'fal',
    iconName: 'lock',
    icon: [448, 512, [128274], "f023", "M128 128v64H320V128c0-53-43-96-96-96s-96 43-96 96zM96 192V128C96 57.3 153.3 0 224 0s128 57.3 128 128v64h16c44.2 0 80 35.8 80 80V432c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V272c0-44.2 35.8-80 80-80H96zM32 272V432c0 26.5 21.5 48 48 48H368c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48H80c-26.5 0-48 21.5-48 48z"]
  };
  var faGlobe = {
    prefix: 'fal',
    iconName: 'globe',
    icon: [512, 512, [127760], "f0ac", "M256 480c16.7 0 40.4-14.4 61.9-57.3c9.9-19.8 18.2-43.7 24.1-70.7H170c5.9 27 14.2 50.9 24.1 70.7C215.6 465.6 239.3 480 256 480zM164.3 320H347.7c2.8-20.2 4.3-41.7 4.3-64s-1.5-43.8-4.3-64H164.3c-2.8 20.2-4.3 41.7-4.3 64s1.5 43.8 4.3 64zM170 160H342c-5.9-27-14.2-50.9-24.1-70.7C296.4 46.4 272.7 32 256 32s-40.4 14.4-61.9 57.3C184.2 109.1 175.9 133 170 160zm210 32c2.6 20.5 4 41.9 4 64s-1.4 43.5-4 64h90.8c6-20.3 9.3-41.8 9.3-64s-3.2-43.7-9.3-64H380zm78.5-32c-25.9-54.5-73.1-96.9-130.9-116.3c21 28.3 37.6 68.8 47.2 116.3h83.8zm-321.1 0c9.6-47.6 26.2-88 47.2-116.3C126.7 63.1 79.4 105.5 53.6 160h83.7zm-96 32c-6 20.3-9.3 41.8-9.3 64s3.2 43.7 9.3 64H132c-2.6-20.5-4-41.9-4-64s1.4-43.5 4-64H41.3zM327.5 468.3c57.8-19.5 105-61.8 130.9-116.3H374.7c-9.6 47.6-26.2 88-47.2 116.3zm-143 0c-21-28.3-37.5-68.8-47.2-116.3H53.6c25.9 54.5 73.1 96.9 130.9 116.3zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z"]
  };
  var faSquare = {
    prefix: 'fal',
    iconName: 'square',
    icon: [448, 512, [9632, 9723, 9724, 61590], "f0c8", "M384 64c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
  };
  var faCircleUser = {
    prefix: 'fal',
    iconName: 'circle-user',
    icon: [512, 512, [62142, "user-circle"], "f2bd", "M412.1 416.6C398.1 361.1 347.9 320 288 320H224c-59.9 0-110.1 41.1-124.1 96.6C58 375.9 32 319 32 256C32 132.3 132.3 32 256 32s224 100.3 224 224c0 63-26 119.9-67.9 160.6zm-28.5 23.4C347.5 465.2 303.5 480 256 480s-91.5-14.8-127.7-39.9c4-49.3 45.3-88.1 95.7-88.1h64c50.4 0 91.6 38.8 95.7 88.1zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-256a48 48 0 1 1 0-96 48 48 0 1 1 0 96zm-80-48a80 80 0 1 0 160 0 80 80 0 1 0 -160 0z"]
  };
  var faThumbtack = {
    prefix: 'fal',
    iconName: 'thumbtack',
    icon: [384, 512, [128204, 128392, "thumb-tack"], "f08d", "M119.9 34.5L107.8 191.4c-.7 9.7-5.9 18.6-13.9 24c-27 18.3-47.3 46.2-55.6 79.6L32 320l144 0V224c0-8.8 7.2-16 16-16s16 7.2 16 16v96l144 0-6.2-25c-8.3-33.4-28.6-61.3-55.6-79.6c-8.1-5.5-13.2-14.3-13.9-24L264.1 34.5c-.1-.8-.1-1.6-.1-2.5H120c0 .8 0 1.6-.1 2.5zM176 352H32c-9.9 0-19.2-4.5-25.2-12.3S-1.4 321.8 1 312.2l6.2-25c10.3-41.3 35.4-75.7 68.7-98.3L85.5 64 88 32H64c-2.9 0-5.6-.8-7.9-2.1C51.2 27.1 48 21.9 48 16C48 7.2 55.2 0 64 0H88 296h24c8.8 0 16 7.2 16 16c0 5.9-3.2 11.1-8.1 13.9c-2.3 1.3-5 2.1-7.9 2.1H296l2.5 32 9.6 124.9c33.3 22.6 58.4 57.1 68.7 98.3l6.2 25c2.4 9.6 .2 19.7-5.8 27.5s-15.4 12.3-25.2 12.3H208V496c0 8.8-7.2 16-16 16s-16-7.2-16-16V352z"]
  };

  exports.faCircleUser = faCircleUser;
  exports.faGlobe = faGlobe;
  exports.faLock = faLock;
  exports.faSquare = faSquare;
  exports.faSquareCheck = faSquareCheck;
  exports.faThumbtack = faThumbtack;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
