(function ($R) {
  $R.add('plugin', 'emailPlaceholders', {
    init: function (app) {
      this.app = app;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.insertion = app.insertion;
    },
    start: function () {
      var dropdown = {};

      if (this.opts.emailPlaceholders === 'privacy-policy') {
        dropdown['privacy-policy'] = {
          title: '{privacy-policy}',
          api: 'plugin.emailPlaceholders.insertPlaceholder',
          args: '{privacy-policy}',
        };
      } else if (this.opts.emailPlaceholders === 'contact') {
        dropdown['contact-person-email'] = {
          title: '{contact-person-email}',
          api: 'plugin.emailPlaceholders.insertPlaceholder',
        };
      } else if (this.opts.emailPlaceholders === 'job-invitation') {
        dropdown['job-title'] = {
          title: '{job-title}',
          api: 'plugin.emailPlaceholders.insertPlaceholder',
          args: '{job-title}',
        };
      } else {
        dropdown['first-name'] = {
          title: '{first-name}',
          api: 'plugin.emailPlaceholders.insertPlaceholder',
          args: '{first-name}',
        };
        dropdown['last-name'] = {
          title: '{last-name}',
          api: 'plugin.emailPlaceholders.insertPlaceholder',
          args: '{last-name}',
        };
        dropdown['full-name'] = {
          title: '{full-name}',
          api: 'plugin.emailPlaceholders.insertPlaceholder',
          args: '{full-name}',
        };
        dropdown['connect-url'] = {
          title: '{connect-url}',
          api: 'plugin.emailPlaceholders.insertPlaceholder',
          args: '{connect-url}',
        };

        if (this.opts.emailPlaceholders === 'job') {
          dropdown['job-title'] = {
            title: '{job-title}',
            api: 'plugin.emailPlaceholders.insertPlaceholder',
            args: '{job-title}',
          };
        }

        if (this.opts.emailPlaceholders === 'gdpr-permissions') {
          dropdown['company-name'] = {
            title: '{company-name}',
            api: 'plugin.emailPlaceholders.insertPlaceholder',
            args: '{company-name}',
          };

          dropdown['privacy-policy-link'] = {
            title: '{privacy-policy-link}',
            api: 'plugin.emailPlaceholders.insertPlaceholder',
            args: '{privacy-policy-link}',
          };

          dropdown['data-privacy-link'] = {
            title: '{data-privacy-link}',
            api: 'plugin.emailPlaceholders.insertPlaceholder',
            args: '{data-privacy-link}',
          };
        }
      }

      var $button = this.toolbar.addButton('emailPlaceholders', {
        title: '<span>{placeholders}</span>',
      });

      $button.setDropdown(dropdown);
    },
    insertPlaceholder: function (buttonName) {
      this.insertion.insertText(buttonName);
    },
  });
})(Redactor);
